<script setup lang="ts">
import { useTemplates } from '@reports/components/Template/composables/useTemplates'

interface Props {
  modelValue?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
})

const emit = defineEmits<{
  (e: 'update:modelValue', template: string): void
}>()

/* === MODEL VALUE === */
const modelValue = useVModel(props, 'modelValue', emit)
const innerValue = ref<string>()
syncRef(modelValue, innerValue)
/* === MODEL VALUE === */

/* === TEMPLATES === */
const { templates, isLoading } = useTemplates()
const options = computed(() =>
  templates.value.map((template) => ({
    label: template.name,
    value: template.id,
  })),
)
/* === TEMPLATES === */
</script>

<template>
  <FormKit
    v-model="innerValue"
    type="listbox"
    name="template"
    placeholder="select a template"
    :options="options"
    :searchable="true"
    :loading="isLoading"
    outer-class="w-full md:w-[300px]"
  />
</template>
